import React from "react";
import { Layout, Row, notification, message } from "antd";

import HeaderContent from "components/HeaderContent";
import FooterContent from "components/FooterContent";

const { Header, Footer, Content } = Layout;

export class Container extends React.Component {
  render() {
    let children = null;

    if (this.props.children) {
      children = React.cloneElement(this.props.children);
    }

    // Ant Design - Global setting for all notifications
    notification.config({ top: 10 });
    message.config({ top: 10, duration: 6 });

    const headerStyle = {
      style: {
        marginBottom: 8,
        height: "56px",
        lineHeight: "56px",
        background: "#fff"
      }
    };

    return (
      <div>
        <Layout>
          <Header {...headerStyle}>
            <HeaderContent />
          </Header>
          <Content style={{ minHeight: 460 }}>
            <Row type="flex" justify="center">
              {children}
            </Row>
          </Content>
          <Footer>
            <FooterContent />
          </Footer>
        </Layout>
      </div>
    );
  }
}

export default Container;

import React from 'react';
import makeMainRoutes from './views/routes'
import {BackTop} from 'antd';

const App = () => {

  const mainRoutes = makeMainRoutes();

  return (
    <div style={{ height: '100%' }}>
      <BackTop />
      {mainRoutes}
    </div>
  )

}

export default App;
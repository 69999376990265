import React from "react";
import { Icon, Card, Row } from "antd";
import QueueAnim from "rc-queue-anim";

const Home = () => {
  return (
    <Card
      title="O F F L I N E"
      bordered={false}
      style={{ marginTop: 40, width: 700 }}
      extra={<Icon type="robot" style={{ fontSize: 24 }} />}
    >
      <Row gutter={16}>
        <QueueAnim delay={400}>
          <div key="1">
            <Card>
              <QueueAnim duration={600}>
                <div key="1">
                  Diese Anwendung steht zur Zeit nicht zur Verfügung.
                </div>
              </QueueAnim>
            </Card>
          </div>
        </QueueAnim>
      </Row>
    </Card>
  );
};

export default Home;

import React from "react";
import { Icon, Row, Col, Typography } from "antd";
import "./FooterContent.css";
import version from "components/Version";

const { Text } = Typography;

function FooterContent() {
  var userProfile;

  return (
    <div>
      <hr />
      <Row>
        <Col span={12}>
          <Text code>
            <Icon type="copyright" />
            {" 2019, "}
            <a
              href="https://hit-services.de"
              target="_blank"
              rel="noopener noreferrer"
            >
              HIT-SERVICES
            </a>
          </Text>
        </Col>
        <Col span={12}>
          <div className="align-right">
            {userProfile && (
              <Text code>
                <Icon type="user" /> {userProfile.email}
              </Text>
            )}
            <Text code>
              <Icon type="info-circle" /> v{version}
            </Text>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default FooterContent;

import React from 'react';
import { Alert, Card, Row } from 'antd';
import logo from 'assets/logo_company-40px-8.png'

const NotFound = (props) => {

  return (
    <Row type="flex" justify="center">
      <Card
        title="404 - Seite nicht gefunden"
        extra={<img src={logo} height="24px" alt="HIT-SERVICES" />}
        style={{ width: 600 }}
      >
        <Alert
          message="Entschuldigung! Die aufgerufene Seite hat sich vermutlich aufgelöst."
          description={
            <span>
              <p>Es kann vielfältige Gründe für das Verschwinden von Internetseiten geben.
              Nicht immer kann dieses Problem sofort gelöst werden. Wir laden Sie jedoch dazu ein,
              es erneut mit einem Besuch der <a href="/">Startseite</a> zu versuchen.</p>
            </span>
          }
          type="error"
          showIcon
        />
      </Card>
    </Row>
  );
};

export default NotFound;

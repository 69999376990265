import React, { Component } from "react";
import { Typography, Row, Col, Menu } from "antd";
import { Link } from "react-router-dom";
import logo from "assets/logo_company-40px-8.png";

const appTitle = process.env.REACT_APP_MAIN_TITLE;
const { Text } = Typography;

class HeaderContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedKeys: []
    };
  }

  render() {
    const { selectedKeys } = this.state;
    var menuItems = [];

    return (
      <Row type="flex" justify="center">
        <Col span={12}>
          <Link to="/">
            <img
              style={{ verticalAlign: "middle", height: 38 }}
              src={logo}
              alt="HIT-SERVICES"
            />
          </Link>
          <Text strong style={{ marginLeft: 12 }}>
            {appTitle}
          </Text>
        </Col>
        <Col span={12}>
          <Menu
            mode="horizontal"
            selectedKeys={selectedKeys}
            style={{ float: "right" }}
          >
            {menuItems}
          </Menu>
        </Col>
      </Row>
    );
  }
}

export default HeaderContent;

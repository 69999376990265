import React from "react";
import { Switch, Route } from "react-router-dom";
import Container from "./container";
import Home from "./Home";
import NotFound from "components/NotFound";

// https://css-tricks.com/react-router-4/

export const makeMainRoutes = () => {
  return (
    <Container>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="*" component={NotFound} />
      </Switch>
    </Container>
  );
};

export default makeMainRoutes;
